import * as React from 'react'
import Layout from '../components/layout'

const ContactPage = () => {
  return (
    <Layout pageTitle="Contact">
      <div className="container mb-16">
        <ul className="text-xl">
          <li className="mb-5">
            <strong>Email:</strong> <a className="underline text-green" href="mailto:sainnovations@hotmail.com">sainnovations@hotmail.com</a>
          </li>
          <li className="mb-5">
            <strong>Phone:</strong> <a className="underline text-green" href="tel:5086673686">508-667-3686</a>
          </li>
          <li className="mb-5">
            <strong><a className="underline text-green" href="https://www.bbb.org/us/ma/brimfield/profile/general-contractor/sa-innovations-0261-336239" target="_blank">Better Business Bureau</a></strong>
          </li>
          <li className="mb-5">
            <strong><a className="underline text-green" href="https://www.instagram.com/s_and_a_innovations/" target="_blank">Instagram</a></strong>
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default ContactPage